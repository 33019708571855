import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import neverWasteFood from "../../../src/assets/home/never-waste-food.svg";
import neverWasteFoodTab from "../../../src/assets/tablet-assets/home/never-waste-food.svg";
import neverWasteFoodMob from "../../../src/assets/mobile-assets/home/never-waste-food.svg";
import CheckScreen from "../../hooks/BreakPoints";

function NeverWasteFoodSection() {
  const textStyles = {
    descriptionSectionStyle:
      "w-1/2 sm:w-[264px] xl:w-3/5 flex sm:items-start items-center justify-center h-full sm:h-[70%]",
    title: "Never ever waste food again",
    description:
      "Stop food from going to waste with our proactive notification system. Receive alerts before items expire, empowering you to use them before it's too late. Say hello to a fridge stocked with fresh ingredients, every time.",
    contentContainerStyle:
      "sm:w-full md:w-[240px] w-[413px] h-auto sm:flex sm:flex-col sm:items-center sm:justify-center",
    headingStyle:
      "font-sf-pro font-semibold sm:w-[180px] sm:text-center sm:text-[22px] sm:leading-[24px] md:text-[24px] md:leading-[25px] text-[40px] leading-[42px] text-secondary sm:mb-[15px] md:mb-[15px] mb-[26px]",
    paragraphStyle:
      "font-sans sm:text-center sm:text-[13px] sm:leading-[17.26px] md:text-[13px] md:leading-[17.26px] text-[16px] leading-[21.25px] font-normal tracking-tight text-left text-secondary",
  };
  // check screen size
  const screenSize = CheckScreen();
  const neverWasteSectionImages = {
    bigScreen: neverWasteFood,
    desktop: neverWasteFood,
    tablet: neverWasteFoodTab,
    mobile: neverWasteFoodMob,
  };
  return (
    <>
      <Container
        classes="mx-auto flex items-center justify-end sm:justify-center h-[548px] sm:h-[602px] xl:h-[700px] md:w-[70%] lg:w-[80%] xl:w-[70%]"
        backgroundStyle="bg-contain bg-center bg-no-repeat"
        backgroundImage={neverWasteSectionImages[screenSize]}
      >
        <DescriptionSection {...textStyles} />
      </Container>
    </>
  );
}

export default NeverWasteFoodSection;
