import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import ImageSection from "../ImageSection";
import heroSection from "../../../src/assets/home/hero-section.png";
import heroSectionTab from "../../../src/assets/tablet-assets/home/hero-section.png";
import heroSectionMob from "../../../src/assets/mobile-assets/home/hero-section.png";
import heroSectionGroup from "../../../src/assets/home/hero-section-group.png";
import heroSectionGroupTab from "../../../src/assets/tablet-assets/home/hero-section-group.png";
import heroSectionGroupMob from "../../../src/assets/mobile-assets/home/hero-section-group.png";
import CheckScreen from "../../hooks/BreakPoints";

function HeroSection() {
  const textStyles = {
    descriptionSectionStyle: "w-full flex items-center justify-center h-full",
    contentContainerStyle:
      "sm:w-[256px] md:w-[360px] lg:w-[553px] xl:w-[553px] h-auto text-center",
    headingStyle:
      "font-sf-pro font-semibold sm:text-[20px] md:text-[28px] lg:text-[40px] xl:text-[40px] sm:leading-[22px] md:leading-[28px] lg:leading-[42px] xl:leading-[42px] text-secondary sm:mb-[20px] mb-[26px]",
    paragraphStyle:
      "font-sans font-normal tracking-tight text-secondary sm:text-[11px] md:text-[13px] lg:text-[16px] xl:text-[16px] sm:leading-[14px] md:leading-[18px] lg:leading-[22px] xl:leading-[22px]",
    title: "Save your food with EcoGrocery app",
    description:
      "Our app is designed to revolutionize how you handle your food inventory, ensuring freshness, reducing waste, and simplifying your meal planning process.",
  };
  const imageStyles = {
    imageSectionStyle: "w-full flex justify-center items-center h-full",
    title: "Save your food with grocery saver app",
  };

  // check screen size
  const screenSize = CheckScreen();
  const heroSectionImages = {
    bigScreen: heroSection,
    desktop: heroSection,
    tablet: heroSectionTab,
    mobile: heroSectionMob,
  };
  const heroSectionGroupImages = {
    bigScreen: heroSectionGroup,
    desktop: heroSectionGroup,
    tablet: heroSectionGroupTab,
    mobile: heroSectionGroupMob,
  };

  return (
    <>
      <Container
        classes="mx-auto flex items-center justify-center  sm:h-[400px] h-[640px] w-full"
        backgroundStyle="bg-cover bg-center bg-no-repeat"
        backgroundImage={heroSectionImages[screenSize]}
      >
        <div className="flex flex-col relative sm:h-[465px] md:h-[650px] lg:h-[750px] xl:h-[750px] sm:w-[269.62px] md:w-[444.19px] lg:w-[537.83px] xl:w-[537.83px] sm:top-[100px] md:top-[200px] lg:top-[226px] xl:top-[226px]">
          <DescriptionSection {...textStyles} />
          <ImageSection
            imageUrl={heroSectionGroupImages[screenSize]}
            {...imageStyles}
          />
        </div>
      </Container>
    </>
  );
}

export default HeroSection;
