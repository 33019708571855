import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import ImageSection from "../ImageSection";
import heroSectionGroup from "../../../src/assets/about/hero-section-group.png";
import heroSectionGroupTab from "../../../src/assets/tablet-assets/about/hero-section-group.png";
import heroSectionGroupMob from "../../../src/assets/mobile-assets/about/hero-section-group.svg";
import heroSection from "../../../src/assets/about/hero-section.png";
import heroSectionTab from "../../../src/assets/tablet-assets/about/hero-section.png";
import heroSectionMob from "../../../src/assets/mobile-assets/about/hero-section.svg";
import CheckScreen from "../../hooks/BreakPoints";

function HeroSection() {
  const textStyles = {
    descriptionSectionStyle:
      "w-1/2 sm:w-[275px] flex items-center justify-center h-full relative md:top-[60px] sm:top-[60px]",
    contentContainerStyle:
      "w-[400px] sm:w-full h-auto text-left md:text-center sm:text-center",
    headingStyle:
      "font-sf-pro font-extrabold text-[50px] md:text-[24px] sm:text-[20px] leading-[50px] text-secondary mb-[26px] md:mb-[1px] sm:mb-[0px]",
    paragraphStyle:
      "font-sans text-[16px] leading-[22px] md:text-[13px] md:leading-[18px] sm:text-[11px] sm:leading-[14px] font-normal tracking-tight text-secondary",
    title: "About EcoGrocery",
    description:
      "Welcome to EcoGrocery, where our passion for food safety, sustainability, and innovation converge to empower users to make a positive impact on their daily lives and the planet.",
  };
  const imageStyles = {
    imageSectionStyle:
      "flex justify-center items-end h-full sm:order-last md:order-last",
    title: "About EcoGrocery",
  };
  // check screen size
  const screenSize = CheckScreen();
  const heroSectionImages = {
    bigScreen: heroSection,
    desktop: heroSection,
    tablet: heroSectionTab,
    mobile: heroSectionMob,
  };
  const heroSectionGroupImages = {
    bigScreen: heroSectionGroup,
    desktop: heroSectionGroup,
    tablet: heroSectionGroupTab,
    mobile: heroSectionGroupMob,
  };
  return (
    <>
      <Container
        classes="mx-auto flex items-end justify-center  h-[650px] sm:h-[400px] w-full"
        backgroundStyle="bg-cover bg-center bg-no-repeat"
        backgroundImage={heroSectionImages[screenSize]}
      >
        <div className="flex sm:flex-col sm:items-center md:flex-col md:items-center h-[510px] md:h-[484px] sm:h-[344px] w-[90%] sm:w-[83%] relative">
          <ImageSection
            imageUrl={heroSectionGroupImages[screenSize]}
            {...imageStyles}
          />
          <DescriptionSection {...textStyles} />
        </div>
      </Container>
    </>
  );
}

export default HeroSection;
