import React from "react";

const InputField = ({ id, label, type = "text", formik }) => (
  <div className="flex flex-col justify-between w-full h-[70px]">
    <label
      htmlFor={id}
      className="text-base font-medium text-gray-light leading-[19.09px]"
    >
      {label}
    </label>
    <input
      type={type}
      id={id}
      placeholder={label}
      className="w-full rounded-[5px] py-3 px-4 border-[0.5px] border-gray-mediumGray text-sm outline-blue-500"
      {...formik.getFieldProps(id)}
    />
    {formik.touched[id] && formik.errors[id] ? (
      <div className="text-red-600 text-sm">{formik.errors[id]}</div>
    ) : null}
  </div>
);

export default InputField;
