import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitterSquare } from "react-icons/fa";
import Container from "../components/Container";
import CheckScreen from "../hooks/BreakPoints";
import logo from "../assets/logo.svg";
import logoTab from "../assets/logoTab.svg";
import logoMob from "../assets/logoMobile.png";

function Footer() {
  // check screen size
  const screenSize = CheckScreen();
  const logoSources = {
    bigScreen: logo,
    desktop: logo,
    tablet: logoTab,
    mobile: logoMob,
  };
  const logoImage = logoSources[screenSize];
  return (
    <footer className="bg-gray-medium text-secondary flex items-end sm:items-center justify-between w-full py-2 h-[380px] sm:h-[650px]">
      <Container classes="mx-auto flex flex-col items-center justify-between md:h-4/5 sm:h-full w-[90%]">
        <div className="mx-auto flex sm:flex-col justify-between sm:justify-around items-start sm:items-center sm:h-[80%] w-full md:text-xs">
          {/* Section 1: Logo and Content */}
          <div className="w-[294.8px] sm:w-[250px] md:w-[221px] mb-8 sm:mb-4 md:mb-0 sm:text-center">
            <img
              src={logoImage}
              className="sm:mx-auto sm:mb-4"
              alt="Your Company Logo"
            />
            <p className="text-[14px] md:text-[11px] sm:text-[11px] sm:leading-[15.44px] text-secondary w-full relative left-20 md:top-2 md:left-0 sm:left-0 sm-w-full">
              Join the movement towards a greener future. With EcoGrocery,
              you're not just saving food, you're also reducing your carbon
              footprint. Track the environmental impact of your actions and see
              the positive change you're making with every meal.
            </p>
          </div>
          <div className="flex sm:flex-wrap justify-between sm:w-full w-[750px] md:w-[430px]">
            {/* Section 2: Company Links */}
            <div className="mb-8 md:mb-0 w-[150px] md:w-[80px] sm:text-xs">
              <h4 className="text-lg md:text-sm sm:text-xs font-bold mb-8">
                Company
              </h4>
              <ul>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/">Terms of Service</Link>
                </li>
              </ul>
            </div>
            {/* Section 3: Customer Care Links */}
            <div className="mb-8 sm:text-xs md:mb-0 w-[150px] md:w-[100px]">
              <h4 className="text-lg md:text-sm sm:text-xs font-bold mb-8">
                Customer Care
              </h4>
              <ul>
                <li>
                  <Link to="/">Feedback</Link>
                </li>
                <li>
                  <Link to="/">Help Center</Link>
                </li>
              </ul>
            </div>
            {/* Section 4: Follow Us */}
            <div className="w-[150px] md:w-[90px] sm:relative left-0">
              <h4 className="text-lg md:text-sm sm:text-xs font-bold mb-8">
                Follow Us On
              </h4>
              <div className="flex space-x-4 sm:space-x-2">
                <FaFacebook size={24} />
                <FaInstagram size={24} />
                <FaTwitterSquare size={24} />
              </div>
            </div>
          </div>
        </div>
        {/* Copyright Section */}
        <div className="py-4 text-center">
          <p className="text-sm text-white">
            EcoGrocery Copyright &copy; All Rights Reserved 2024
          </p>
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
