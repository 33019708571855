import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import ImageSection from "../ImageSection";
import insightfulAnalytics from "../../../src/assets/home/insightful-analytics.png";
import insightfulAnalyticsTab from "../../../src/assets/tablet-assets/home/insightful-analytics.png";
import insightfulAnalyticsMob from "../../../src/assets/mobile-assets/home/insightful-analytics.png";
import CheckScreen from "../../hooks/BreakPoints";

function AnalyticsSection() {
  const textStyles = {
    descriptionSectionStyle:
      "w-1/2 sm:w-[262px] flex items-center justify-center h-full",
    contentContainerStyle:
      "sm:w-full md:w-[262px] lg:w-[418px] xl:w-[418px] h-auto sm:flex sm:flex-col sm:items-center sm:justify-center",
    headingStyle:
      "font-sf-pro font-semibold sm:text-[22px] md:text-[24px] leading-[42px] lg:text-[40px] lg:leading-[42px] xl:text-[40px] xl:leading-[42px] text-gray-medium sm:mb-[15px] mb-[26px]",
    paragraphStyle:
      "font-sans sm:text-center text-[13px] leading-[20px] lg:text-[16px] lg:leading-[24px] xl:text-[16px] xl:leading-[24px] font-normal tracking-tight text-left text-gray-lightest",
    title: "Insightful Analytics",
    description:
      "Track your food consumption like never before with our powerful analytics tool. Gain valuable insights into your usage patterns, see how much you've saved by preventing waste, and make informed decisions to optimize your food management strategy.",
  };
  const imageStyles = {
    imageSectionStyle: "flex justify-center items-center h-full",
    title: "Insightful Analytics",
  };
  // check screen size
  const screenSize = CheckScreen();
  const insightSectionImages = {
    bigScreen: insightfulAnalytics,
    desktop: insightfulAnalytics,
    tablet: insightfulAnalyticsTab,
    mobile: insightfulAnalyticsMob,
  };
  return (
    <>
      <Container classes="mx-auto flex sm:flex-col items-center justify-evenly sm:h-[600px] md:h-[290.31px] lg:h-[632px] xl:h-[632px] w-[90%]">
        <DescriptionSection {...textStyles} />
        <ImageSection
          imageUrl={insightSectionImages[screenSize]}
          {...imageStyles}
        />
      </Container>
    </>
  );
}

export default AnalyticsSection;
