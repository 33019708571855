import React from "react";
import HeroSection from "../components/about/HeroSection";
import OurMission from "../components/about/OurMission";
import SpaceManager from "../components/SpaceManager";
import OurValues from "../components/about/OurValues";
import TeamSection from "../components/about/TeamSection";
import CheckScreen from "../hooks/BreakPoints";

function About() {
  const screen = CheckScreen();
  return (
    <>
      <HeroSection />
      <SpaceManager size={screen === "mobile" ? "2rem" : "10rem"} />
      <OurMission />
      <SpaceManager size={screen === "mobile" ? "5rem" : "8rem"} />
      <OurValues />
      <SpaceManager size={screen === "mobile" ? "12rem" : "15rem"} />
      <TeamSection />
      <SpaceManager size="15rem" />
    </>
  );
}

export default About;
