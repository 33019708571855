import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import ImageSection from "../ImageSection";
import ourMission from "../../../src/assets/about/our-mission.png";
import ourMissionTab from "../../../src/assets/tablet-assets/about/our-mission.png";
import CheckScreen from "../../hooks/BreakPoints";

function OurMission() {
  const textStyles = {
    descriptionSectionStyle:
      "w-1/2 sm:w-[277px] flex items-center justify-center h-full",
    contentContainerStyle: "w-[445px] sm:w-full h-auto",
    headingStyle:
      "font-sf-pro font-semibold sm:text-center text-[40px] md:text-[24px] sm:text-[22px] leading-[50px] text-gray-medium mb-[26px] md:mb-[1px] sm:mb-[1px]",
    paragraphStyle:
      "font-sans sm:text-center text-[16px] leading-[22px] md:text-[13px] md:leading-[18px] sm:text-[13px] sm:leading-[18px] font-normal tracking-tight text-left text-gray-lightest",
    title: "Our Mission",
    description:
      "At EcoGrocery, our mission is simple yet profound, to revolutionize the way people manage their food inventory, reduce waste, and promote sustainability. We believe that every action, no matter how small, contributes to a larger movement towards a greener, more efficient future.",
  };
  const imageStyles = {
    imageSectionStyle: "flex justify-center items-center h-full",
    title: "Our Mission",
  };
  // check screen size
  const screenSize = CheckScreen();
  return (
    <>
      <Container classes="mx-auto flex sm:flex-col items-center justify-evenly h-[520.45px] md:h-[200px] sm:h-[590px] w-[90%]">
        <DescriptionSection {...textStyles} />
        <ImageSection
          imageUrl={screenSize === "tablet" ? ourMissionTab : ourMission}
          {...imageStyles}
        />
      </Container>
    </>
  );
}

export default OurMission;
