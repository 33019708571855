import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import CheckScreen from "../hooks/BreakPoints";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoTab from "../assets/logoTab.svg";
import logoMob from "../assets/logoMobile.png";

// NavItem component to handle individual navigation items
function NavItem({ to, active, children, className, activeClasses }) {
  const location = useLocation();
  return (
    <li className={className}>
      <Link
        to={to}
        className={`inline-flex items-center justify-center w-full h-full rounded-lg px-4 py-2 ${
          location.pathname === to ? `${activeClasses}` : "hover:text-gray-200"
        }`}
      >
        {children}
      </Link>
    </li>
  );
}

const Navbar = () => {
  // State to manage the navbar's visibility
  const [nav, setNav] = useState(false);
  const location = useLocation();
  const screenSize = CheckScreen();
  const logoSources = {
    bigScreen: logo,
    desktop: logo,
    tablet: logoTab,
    mobile: logoMob,
  };
  const logoImage = logoSources[screenSize];
  const navItems = [
    { id: 1, text: "Home", to: "/" },
    { id: 2, text: "About", to: "/about" },
    { id: 3, text: "Contact", to: "/contact" },
  ];

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setNav(!nav);
  };
  useEffect(() => {
    setTimeout(() => {
      setNav(false); // Close the mobile menu when the route changes
    }, 500);
  }, [location.pathname]);

  return (
    <nav className="bg-transparent text-white px-4 sm:px-0 w-full py-2 flex justify-between items-center absolute top-8">
      <img src={logoImage} alt="App Logo" />

      {/* Desktop Navigation */}
      <ul className="block sm:hidden flex space-x-4">
        {navItems.map((item) => (
          <NavItem
            key={item.id}
            to={item.to}
            active={location.pathname === item.to}
            className=""
            activeClasses="text-primary bg-secondary"
          >
            {item.text}
          </NavItem>
        ))}
      </ul>

      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="hidden sm:block">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>

      {/* Mobile Navigation Menu */}
      <ul
        className={
          nav
            ? "z-10 fixed hidden sm:block left-0 top-0 w-[300px] h-full bg-secondary ease-in-out duration-500"
            : "ease-in-out w-[400px] duration-500 fixed top-0 bottom-0 left-[-100%] sm:left-[-110%]"
        }
      >
        <div
          onClick={handleNav}
          className="p-4 flex justify-end border-b  border-gray-medium-600"
        >
          <AiOutlineClose size={20} color="gray" />
        </div>
        {navItems.map((item) => (
          <NavItem
            key={item.id}
            to={item.to}
            active={location.pathname === item.to}
            className="p-2 border-b rounded-sm duration-300 text-gray-medium cursor-pointer border-gray-medium-600"
            activeClasses="text-secondary bg-gray-medium"
          >
            {item.text}
          </NavItem>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
