import React from 'react';

const Container = ({ children, classes, backgroundStyle, backgroundImage }) => {
  const containerClasses = `${classes} ${backgroundImage ? backgroundStyle : ''}`;
  const style = backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {};

  return (
    <div className={containerClasses} style={style}>
      {children}
    </div>
  );
};

export default Container;
