import React from "react";
import Container from "../components/Container";
import policyBanner from "../assets/policy/policy-banner.png";
import SpaceManager from "../components/SpaceManager";
import CheckScreen from "../hooks/BreakPoints";

// Component for each section
function PolicySection({ mainHeading, title, content, subSections }) {
  return (
    <div className="mb-10">
      {mainHeading && (
        <h1 className="text-4xl sm:text-md font-semibold mb-4 text-gray-medium">
          {mainHeading}
        </h1>
      )}
      {title && (
        <h2 className="text-xl font-semibold my-4 text-gray-medium">{title}</h2>
      )}
      {content && <p className="mb-4">{content} {title === '9. Contact Us' && <a href="mailto:support@eco-grocery.com" target="_blank" className="font-semibold"> support@eco-grocery.com</a>}</p>}
      {subSections &&
        (subSections[0].title ? (
          subSections.map((subSection, index) => (
            <div key={index}>
              <h3 className="text-base font-semibold text-gray-lightest">
                {subSection.title}
              </h3>
              <p className="mb-4">{subSection.content}</p>
            </div>
          ))
        ) : (
          <ul className="list-disc ml-6 mb-4">
            {subSections.map((subSection, index) => (
              <li key={index}>{subSection}</li>
            ))}
          </ul>
        ))}
    </div>
  );
}

function PrivacyPolicy() {
  const screen = CheckScreen();
  const sections = [
    {
      mainHeading: "Privacy Policy",
      content:
        "Thank you for using the EcoGrocery mobile application dedicated to addressing food waste. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our mobile application. Please read this Privacy Policy carefully.",
    },
    {
      title: "1. Collection of Information",
      content:
        "We may collect certain information about you when you use our mobile application. This information may include:",
      subSections: [
        {
          title: "Usage Information:",
          content:
            "We may collect information about how you use the application, such as the features you access, the actions you take, and the frequency and duration of your activities.",
        },
        {
          title: "Device Information:",
          content:
            "We may collect information about your mobile device, including the type of device, operating system version, unique device identifiers, and mobile network information.",
        },
        {
          title: "Location Information:",
          content:
            "With your consent, we may collect information about your precise location using GPS or similar technology when you use certain features of the application.",
        },
      ],
    },
    {
      title: "2. Use of Information",
      subSections: [
        "To provide, maintain, and improve our mobile application and services.",
        "To personalize your experience and tailor content and features to your interests.",
        "To communicate with you, respond to your inquiries, and provide customer support.",
        "To send you important notices, updates, and promotional messages.",
        "To analyze trends, monitor usage, and gather demographic information.",
        "To detect, prevent, and address technical issues or security vulnerabilities.",
        "To comply with legal obligations and enforce our policies.",
      ],
    },
    {
      title: "3. Disclosure of Information",
      subSections: [
        "With service providers, vendors, and other partners who assist us in operating the application and providing services.",
        "With advertisers and advertising networks to deliver relevant advertisements to you.",
        "With law enforcement authorities, government agencies, or other third parties if we believe disclosure is necessary to comply with applicable laws, regulations, or legal processes, or to protect the rights, property, or safety of our users or others.",
        "In connection with a merger, acquisition, or sale of all or a portion of our assets, as permitted by law.",
      ],
    },
    {
      title: "4. Data Retention",
      content:
        "We will retain your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.",
    },
    {
      title: "5. Security",
      content:
        "We take reasonable measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.",
    },
    {
      title: "6. Your Choices",
      content:
        "You may choose not to provide certain information, but this may limit your ability to use certain features of the application. You can also opt-out of certain communications or modify your preferences at any time by contacting us or adjusting your settings within the application.",
    },
    {
      title: "7. Children’s Privacy",
      content:
        "Our mobile application is not intended for children under the age of 13, and we do not knowingly collect personal information from children under this age. If you believe we have collected information from a child under 13 without parental consent, please contact us, and we will take appropriate steps to remove the information.",
    },
    {
      title: "8. Changes To This Privacy Policy",
      content:
        "We reserve the right to modify this Privacy Policy at any time, so please review it frequently. If we make material changes to this Privacy Policy, we will notify you by email or by posting a notice within the application prior to the changes taking effect. Your continued use of the application after the effective date of the revised Privacy Policy constitutes your acceptance of the changes.",
    },
    {
      title: "9. Contact Us",
      content:
        "If you have any questions or concerns about this Privacy Policy or our practices regarding your information, please contact us at",
    },
  ];

  return (
    <>
      <Container
        classes="mx-auto flex items-center justify-center  h-[142px] w-full"
        backgroundStyle="bg-cover bg-center bg-no-repeat"
        backgroundImage={policyBanner}
      ></Container>
      <SpaceManager size={screen === "mobile" ? "4rem" : "10rem"} />
      <div className="max-w-[90%] md:text-sm sm:text-xs mx-auto md:py-2 py-8">
        {sections.map((section, index) => (
          <PolicySection key={index} {...section} />
        ))}
        <div className="w-full flex items-center justify-between my-20">
          <h2 className="text-base sm:text-xs font-semibold text-gray-medium">
            Effective Date: 04/28/2024
          </h2>
          <h2 className="text-base sm:text-xs font-semibold text-gray-medium">
            Last Updated: 04/28/2024
          </h2>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
