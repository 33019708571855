import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import ImageSection from "../ImageSection";
import inventoryManagement from "../../../src/assets/home/inventory-management.png";
import inventoryManagementTab from "../../../src/assets/tablet-assets/home/inventory-management.png";
import inventoryManagementMob from "../../../src/assets/mobile-assets/home/inventory-management.png";
import CheckScreen from "../../hooks/BreakPoints";

function InventoryManagementSection() {
  const textStyles = {
    descriptionSectionStyle: "flex items-center justify-center h-full",
    contentContainerStyle:
      "sm:w-[254px] md:w-[285px] lg:w-[445px] xl:w-[445px] sm:h-[141px] h-[154px] sm:text-center",
    headingStyle:
      "font-sf-pro font-semibold sm:text-[22px] sm:leading-[26.25px] md:text-[28px] md:leading-[33.41px] lg:text-[40px] lg:leading-[47.73px] xl:text-[40px] xl:leading-[47.73px] text-gray-medium sm:mb-[20px] mb-[26px]",
    paragraphStyle:
      "font-sans sm:text-center font-normal text-[13px] leading-[18px] lg:text-[16px] lg:leading-[22px] xl:text-[16px] xl:leading-[22px] tracking-tight text-left text-gray-lightest",
    title: "Effortless Food Inventory Management",
    description:
      "Say goodbye to fridge chaos and expired groceries. With our intuitive interface, managing your food inventory has never been easier. Simply scan, track, and organize your items with a few taps",
  };
  const imageStyles = {
    imageSectionStyle: "flex justify-center items-center h-full",
    title: "Effortless Food Inventory Management",
  };
  // check screen size
  const screenSize = CheckScreen();
  const inventorySectionImages = {
    bigScreen: inventoryManagement,
    desktop: inventoryManagement,
    tablet: inventoryManagementTab,
    mobile: inventoryManagementMob,
  };
  return (
    <>
      <Container classes="mx-auto flex sm:flex-col items-center sm:justify-center justify-evenly sm:h-[550px] md:h-[258.45px] lg:h-[520.45px] xl:h-[520.45px] w-[90%]">
        <DescriptionSection {...textStyles} />
        <ImageSection
          imageUrl={inventorySectionImages[screenSize]}
          {...imageStyles}
        />
      </Container>
    </>
  );
}

export default InventoryManagementSection;
