import React from "react";
import Container from "../Container";
import axios from "axios";
import { useFormik } from "formik";
import validationSchema from "../form/validationSchema";
import { showSuccessToast, showErrorToast } from "../Toast";
import InputField from "../form/InputField";
import TextAreaField from "../form/TextAreaField";
import SubmitButton from "../form/SubmitButton";

const ContactForm = () => {
  const createEmailData = (values) => {
    return {
      subject: `From ${values.name} - ${values.email}`,
      body: `${values.message}`,
      recipient: "support@eco-grocery.com",
      from: "admin@eco-grocery.com",
    };
  };

  const sendEmail = async (emailData) => {
    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "http://localhost:3000/send-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: emailData,
    };
    return await axios(config);
  };

  const handleResponse = (response, resetForm) => {
    if (response.status === 200) {
      showSuccessToast("Email sent successfully");
      resetForm();
    } else {
      showErrorToast(
        "An error occurred while sending email. Please try again."
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      message: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        const emailData = createEmailData(values);
        const response = await sendEmail(emailData);
        handleResponse(response, resetForm);
      } catch (error) {
        console.error(error);
        showErrorToast(
          "An error occurred while sending email. Please try again."
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <Container classes="mx-auto w-[90%] relative md:bottom-[190px] sm:bottom-[100px] bottom-[240px]">
      <form
        className="mx-auto p-5 bg-secondary font-[sans-serif] w-[460px] h-[594px] sm:w-[295px] sm:h-[550px] rounded-[5px] shadow-3xl flex flex-col items-center justify-evenly"
        onSubmit={formik.handleSubmit}
      >
        <div className="w-[378px] sm:w-[85%] h-[15%] flex items-center">
          <h1 className="text-xl text-gray-medium font-semibold leading-[23.87px]">
            Contact Us
          </h1>
        </div>
        <div className="flex flex-col items-center justify-between gap-[2px] h-[80%] w-[85%]">
          <InputField id="name" label="Name" formik={formik} />
          <InputField id="email" label="Email" type="email" formik={formik} />
          <TextAreaField id="message" label="Message" formik={formik} />
          <SubmitButton isSubmitting={formik.isSubmitting} />
        </div>
      </form>
    </Container>
  );
};

export default ContactForm;
