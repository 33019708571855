import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import ImageSection from "../ImageSection";
import recipeSuggestions from "../../../src/assets/home/recipe-suggestions.png";
import recipeSuggestionsTab from "../../../src/assets/tablet-assets/home/recipe-suggestions.png";
import recipeSuggestionsMob from "../../../src/assets/mobile-assets/home/recipe-suggestions.png";
import CheckScreen from "../../hooks/BreakPoints";

function SuggestionsSection() {
  const textStyles = {
    descriptionSectionStyle:
      "w-1/2 sm:w-[271.85px] flex items-center justify-center h-full",
    contentContainerStyle:
      "sm:w-full md:w-[271.85px] lg:w-[418px] xl:w-[418px] h-auto sm:flex sm:flex-col sm:items-center sm:justify-center",
    headingStyle:
      "font-sf-pro font-semibold sm:w-[200px] sm:text-center sm:text-[22px] md:text-[24px] leading-[28px] lg:text-[40px] lg:leading-[42px] xl:text-[40px] xl:leading-[42px] text-gray-medium sm:mb-[15px] mb-[26px]",
    paragraphStyle:
      "font-sans sm:text-center text-[13px] leading-[18.3px] lg:text-[16px] lg:leading-[22.53px] xl:text-[16px] xl:leading-[22.53px] font-normal tracking-tight text-left text-gray-lightest",
    title: "Customized Recipe Suggestions",
    description:
      "Stuck on what to cook? Let EcoGrocery inspire you! Our app analyzes your inventory and suggests mouth-watering recipes tailored to the ingredients you have on hand. Say goodbye to recipe searches and hello to delicious meals made easy.",
  };
  const imageStyles = {
    imageSectionStyle: "flex justify-center items-center h-full",
    title: "Customized Recipe Suggestions",
  };
  // check screen size
  const screenSize = CheckScreen();
  const recipeWasteSectionImages = {
    bigScreen: recipeSuggestions,
    desktop: recipeSuggestions,
    tablet: recipeSuggestionsTab,
    mobile: recipeSuggestionsMob,
  };
  return (
    <>
      <Container classes="mx-auto flex sm:flex-col items-center justify-evenly sm:h-[650px] md:h-[330px] lg:h-[632px] xl:h-[632px] w-[90%]">
        <DescriptionSection {...textStyles} />
        <ImageSection
          imageUrl={recipeWasteSectionImages[screenSize]}
          {...imageStyles}
        />
      </Container>
    </>
  );
}

export default SuggestionsSection;
