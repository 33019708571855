import { useMediaQuery } from "react-responsive";

// Define your breakpoint sizes here
const BREAKPOINTS = {
  md: { minWidth: 768, maxWidth: 1299.99 },
  lg: { minWidth: 1300, maxWidth: 1439.99 },
  xl: { minWidth: 1440 },
};

const CheckScreen = () => {
  // Define the media queries for each breakpoint
  const matches = {
    md: useMediaQuery(BREAKPOINTS.md),
    lg: useMediaQuery(BREAKPOINTS.lg),
    xl: useMediaQuery(BREAKPOINTS.xl),
  };

  if (matches.md && window.outerWidth >= 768) {
    return "tablet";
  } else if (matches.lg) {
    return "desktop";
  } else if (matches.xl) {
    return "bigScreen";
  } else if (window.innerWidth < 768) {
    return "mobile";
  }
};

export default CheckScreen;
