import React from 'react';

function PlayStoreButton({ icon, link, iconClasses, buttonText, storeName, buttonClasses, buttonTextClasses, storeTextClasses }) {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
    <button
      className={`flex items-center justify-between px-4 rounded-lg ${buttonClasses}`}
    >
      {icon && (
        <img
          src={icon}
          alt={`${storeName} Icon`}
          className={iconClasses}
        />
      )}
      <div className="flex flex-col text-left px-1">
        <span className={buttonTextClasses}>{buttonText}</span>
        <span className={storeTextClasses}>{storeName}</span>
      </div>
    </button>
    </a>
  );
}

export default PlayStoreButton;
