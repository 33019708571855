import React from "react";
import HeroSection from "../components/contact/HeroSection";
import ContactForm from "../components/contact/ContactForm";

function Contact() {
  return (
    <>
      <HeroSection />
      <ContactForm />
    </>
  );
}

export default Contact;
