import React from "react";
import Container from "../Container";

function Card({ title, description }) {
  return (
    <div className="w-[330px] h-[330px] md:w-[194px] md:h-[194px] sm:w-[194px] sm:h-[194px] flex justify-center items-center bg-white border border-green rounded-[30px]">
      <div className="w-[232px] h-[170px] gap-[30px] md:w-[145px] md:h-[110px] md:gap-[8px] sm:w-[145px] sm:h-[110px] sm:gap-[8px] flex flex-col justify-center items-center text-center">
        <h2 className="text-[25px] md:text-[18px] sm:text-[18px] leading-[50px] font-semibold text-gray-medium">
          {title}
        </h2>
        <p className="text-[16px] leading-[22px] md:text-[10px] md:leading-[14px] sm:text-[10px] sm:leading-[14px] text-gray-lightest">
          {description}
        </p>
      </div>
    </div>
  );
}

function OurValues() {
  const valuesData = [
    {
      title: "Innovation",
      description:
        "We are committed to staying at the forefront of technological advancements, continuously improving and evolving our app to meet the changing needs of our users.",
    },
    {
      title: "Sustainability",
      description:
        "We believe in the importance of protecting our planet for future generations. Through our efforts to reduce food waste and carbon emissions, we aim to contribute to a more sustainable world.",
    },
    {
      title: "Empowerment",
      description:
        "We empower our users with the tools and knowledge they need to take control of their food management process, make informed decisions, and lead healthier, more fulfilling lives.",
    },
  ];
  return (
    <Container classes="mx-auto flex items-center justify-center h-[438px] md:h-[280px] sm:h-[700px] w-[90%]">
      <div className="w-[1090px] md:w-[870px] sm:w-full flex flex-col justify-between items-center h-full">
        <div className="w-1/2 flex items-center justify-center">
          <h1 className="text-[40px] md:text-[24px] sm:text-[24px] font-semibold leading-[50px]">
            Our Values
          </h1>
        </div>
        <div className="w-full flex sm:flex-col sm:h-[680px] justify-around sm:items-center">
          {valuesData.map((value, index) => (
            <Card
              key={index}
              title={value.title}
              description={value.description}
            />
          ))}
        </div>
      </div>
    </Container>
  );
}

export default OurValues;
