import React from "react";

const TextAreaField = ({ id, label, formik }) => (
  <div className="flex flex-col justify-between w-full h-[160px]">
    <label
      htmlFor={id}
      className="text-base font-medium text-gray-light leading-[19.09px]"
    >
      {label}
    </label>
    <textarea
      id={id}
      placeholder={label}
      rows="6"
      className="w-full rounded-[5px] px-4 text-sm pt-3 outline-blue-500 border-[0.5px] border-gray-mediumGray"
      {...formik.getFieldProps(id)}
    ></textarea>
    {formik.touched[id] && formik.errors[id] ? (
      <div className="text-red-600 text-sm">{formik.errors[id]}</div>
    ) : null}
  </div>
);

export default TextAreaField;
