import React from "react";
import InventoryManagementSection from "../components/home/InventorySectoin";
import SuggestionsSection from "../components/home/SuggestionsSection";
import AnalyticsSection from "../components/home/AnalyticsSection";
import NeverWasteFoodSection from "../components/home/NeverWasteFoodSection";
import HeroSection from "../components/home/HeroSection";
import GetStarted from "../components/home/GetStarted";
import SpaceManager from "../components/SpaceManager";
import CheckScreen from "../hooks/BreakPoints";

function Home() {
  const screen = CheckScreen();
  return (
    <>
      <HeroSection />
      <SpaceManager size={screen === "mobile" ? "10rem" : "20rem"} />
      <InventoryManagementSection />
      <SpaceManager size="8rem" />
      <NeverWasteFoodSection />
      <SpaceManager size={screen === "mobile" ? "3rem" : "8rem"} />
      <SuggestionsSection />
      <SpaceManager size={screen === "mobile" ? "2rem" : "8rem"} />
      <AnalyticsSection />
      <SpaceManager size="8rem" />
      <GetStarted />
      <SpaceManager size="10rem" />
    </>
  );
}

export default Home;
