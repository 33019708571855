import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string()
    .max(50, "Name must be 50 characters or less")
    .required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  message: Yup.string()
    .max(500, "Message must be 500 characters or less")
    .required("Message is required"),
});

export default validationSchema;
