import React from "react";
import Container from "../Container";
import teamMember1 from "../../assets/about/team-member-1.png";
import teamMember2 from "../../assets/about/team-member-2.png";
import teamMember3 from "../../assets/about/team-member-3.png";
import teamMember1Tab from "../../assets/tablet-assets/about/team-member-1.png";
import teamMember2Tab from "../../assets/tablet-assets/about/team-member-2.png";
import teamMember3Tab from "../../assets/tablet-assets/about/team-member-3.png";
import teamMember1Mob from "../../assets/mobile-assets/about/team-member-1.png";
import teamMember2Mob from "../../assets/mobile-assets/about/team-member-2.png";
import teamMember3Mob from "../../assets/mobile-assets/about/team-member-3.png";
import CheckScreen from "../../hooks/BreakPoints";

function TeamSection() {
  const screen = CheckScreen();
  const teamMember1Images = {
    bigScreen: teamMember1,
    desktop: teamMember1,
    tablet: teamMember1Tab,
    mobile: teamMember1Mob,
  };
  const teamMember2Images = {
    bigScreen: teamMember2,
    desktop: teamMember2,
    tablet: teamMember2Tab,
    mobile: teamMember2Mob,
  };
  const teamMember3Images = {
    bigScreen: teamMember3,
    desktop: teamMember3,
    tablet: teamMember3Tab,
    mobile: teamMember3Mob,
  };
  const teamMembers = [
    {
      name: "Warren James",
      title: "Front End Developer",
      image: teamMember1Images[screen],
    },
    {
      name: "Usman Yousaf",
      title: "Senior Developer",
      image: teamMember2Images[screen],
    },
    {
      name: "Jenny Montez",
      title: "UI/UX Designer",
      image: teamMember3Images[screen],
    },
  ];
  return (
    <Container classes="mx-auto flex items-center justify-center h-[453px] sm:h-[773px] w-[90%]">
      <div className="w-[886px] flex flex-col gap-[100px] justify-center items-center md:w-[596px]">
        {/* Description Section (unchanged) */}
        <div className="text-center mb-8 gap-[50px] sm:gap-[20px] flex flex-col h-[156px]">
          <h2 className="text-[40px] md:text-[24px] sm:text-[24px] font-semibold text-gray-medium leading-[50px]">
            Meet The Team
          </h2>
          <p className="text-base md:text-[16px] sm:text-[13px] font-normal text-gray-lightest leading-[22px] sm:leading-[18px]">
            Behind EcoGrocery is a dedicated team of food enthusiasts, tech
            experts, and sustainability advocates who are passionate about
            making a difference. From developers and designers to food safety
            experts and environmentalists, each member of our team brings unique
            skills and perspectives to the table, united by a shared vision of
            creating positive change.
          </p>
        </div>

        {/* Team Members Section */}
        <div className="flex sm:flex-col justify-between items-center h-[297px] sm:h-[686px] w-[80%] md:w-[596px]">
          {teamMembers.map((member) => (
            <div key={member.name} className="text-center">
              <img
                src={member.image}
                alt={member.name}
                className="rounded-full h-40 w-40 md:h-[120px] md:w-[120px] sm:h-[120px] sm:w-[120px] mx-auto mb-2"
              />
              <h3 className="text-lg font-semibold text-gray-medium leading-[22px] mt-4">
                {member.name}
              </h3>
              <p className="text-gray-lightest text-sm leading-[22px] font-normal">
                {member.title}
              </p>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
}

export default TeamSection;
