import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import heroSection from "../../../src/assets/contact/hero-section.png";
import heroSectionTab from "../../../src/assets/tablet-assets/contact/hero-section.png";
import heroSectionMob from "../../../src/assets/mobile-assets/contact/hero-section.png";
import CheckScreen from "../../hooks/BreakPoints";

function HeroSection() {
    // check screen size
  const screenSize = CheckScreen();
  const textStyles = {
    descriptionSectionStyle:
      "w-full sm:w-[340px] flex items-center sm:items-end justify-center md:h-[75%] sm:h-[62%] h-[80%]",
    contentContainerStyle:
      "md:w-[438px] sm:w-full w-[539px] h-auto text-center flex flex-col gap-[36px]",
    headingStyle:
      "font-sf-pro font-semibold md:text-[24px] sm:text-[20px] text-[45px] md:leading-[0px] sm:leading-[0px] leading-[46px] text-secondary",
    paragraphStyle:
      "font-sans md:text-[13px] md:leading-[18px] sm:text-[13px] sm:leading-[18px] text-[16px] leading-[22px] font-normal text-secondary",
    title: "Get in touch with us",
    description:
      "Have questions about our mobile app? Need technical support or want to share feedback? We're here to help! Reach out to us using the contact form below or via email. We look forward to hearing from you!",
  };
  const heroSectionImages = {
    bigScreen: heroSection,
    desktop: heroSection,
    tablet: heroSectionTab,
    mobile: heroSectionMob,
  };
  return (
    <>
      <Container
        classes="mx-auto flex items-start justify-center  h-[650px] sm:h-[400px] w-full"
        backgroundStyle="bg-cover bg-center bg-no-repeat"
        backgroundImage={heroSectionImages[screenSize]}
      >
        <DescriptionSection {...textStyles} />
      </Container>
    </>
  );
}

export default HeroSection;
