// DescriptionSection.js
import React from "react";

function DescriptionSection({
  title,
  description,
  descriptionSectionStyle,
  contentContainerStyle,
  headingStyle,
  paragraphStyle,
  children,
}) {
  return (
    <div className={`${descriptionSectionStyle}`}>
      <div className={contentContainerStyle}>
        <h3 className={headingStyle}>{title}</h3>
        <p className={paragraphStyle}>{description}</p>
        {children}
      </div>
    </div>
  );
}

export default DescriptionSection;
