import React from "react";
import Container from "../Container";
import DescriptionSection from "../DescriptionSection";
import getStarted from "../../../src/assets/home/get-started.png";
import getStartedTab from "../../../src/assets/tablet-assets/home/get-started.png";
import getStartedMob from "../../../src/assets/mobile-assets/home/get-started.png";
import googlePlayIcon from "../../../src/assets/home/google-play-icon.png";
import appleIcon from "../../../src/assets/home/apple-icon.png";
import PlayStoreButton from "../PlayStoreButton";
import CheckScreen from "../../hooks/BreakPoints";

function NeverWasteFoodSection() {
  const textStyles = {
    descriptionSectionStyle:
      "w-[80%] flex items-center sm:justify-center justify-start h-full",
    contentContainerStyle:
      "sm:w-[243px] md:w-[243px] w-[391px] h-auto gap-[20px] sm:gap-[17px] sm:flex sm:flex-col sm:justify-center sm:items-center sm:relative sm:bottom-20",
    headingStyle:
      "font-sf-pro font-semibold sm:text-center sm:text-[24px] md:text-[24px] text-[40px] leading-[42px] text-secondary mb-[26px] md:mb-[15px] sm:mb-[0px]",
    paragraphStyle:
      "font-sans sm:text-center sm:text-[13px] sm:leading-[16px] md:text-[13px] md:leading-[16px] text-[16px] leading-[22px] font-normal tracking-tight text-left text-secondary",
    title: "Get Started Today",
    description:
      "Ready to revolutionize the way you manage your food? Download EcoGrocery now and take the first step towards a fresher, more sustainable future. Your fridge and the planet will thank you.",
  };
  // check screen size
  const screenSize = CheckScreen();
  const getStartedTSectionImages = {
    bigScreen: getStarted,
    desktop: getStarted,
    tablet: getStartedTab,
    mobile: getStartedMob,
  };

  return (
    <>
      <Container
        classes="mx-auto flex items-center justify-center h-[379px] sm:h-[520px] xl:h-[479px] md:w-[70%] lg:w-[80%] xl:w-[70%]"
        backgroundStyle="bg-contain bg-center bg-no-repeat"
        backgroundImage={getStartedTSectionImages[screenSize]}
      >
        <DescriptionSection {...textStyles}>
          <div className="flex items-center justify-between w-[350px] h-[62.9px] sm:w-[228px] md:w-[225px] md:h-[40px] mt-10 sm:mt-2 md:mt-4">
            <PlayStoreButton
              icon={googlePlayIcon}
              iconClasses="md:w-[15px] md:h-[17px] sm:w-[15px] sm:h-[17px]"
              buttonText="GET IT ON"
              storeName="Google Play"
              buttonClasses="bg-gray-medium text-secondary sm:w-[110px] md:w-[110px] lg:w-[170px] xl:w-[170px] h-[45px] lg:h-[62.9px] xl:h-[62.9px]"
              buttonTextClasses="text-[11px] sm:text-[6px] md:text-[6px] font-sans"
              storeTextClasses="text-[18px] sm:text-[10px] md:text-[10px] font-sans"
              link=""
            />
            <PlayStoreButton
              icon={appleIcon}
              iconClasses="md:w-[15px] md:h-[17px] sm:w-[15px] sm:h-[17px]"
              buttonText="Download on the"
              storeName="App Store"
              buttonClasses="bg-gray-medium text-secondary sm:w-[110px] md:w-[110px] lg:w-[170px] lg:h-[62.9px] xl:w-[170px] xl:h-[62.9px] h-[45px]"
              buttonTextClasses="text-[11px] sm:text-[6px] md:text-[6px]  font-sans"
              storeTextClasses="text-[18px] sm:text-[10px] md:text-[10px] font-sans"
              link="https://apps.apple.com/us/app/ecogrocery-app/id6479983232"
            />
          </div>
        </DescriptionSection>
      </Container>
    </>
  );
}

export default NeverWasteFoodSection;
